<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>تعديل منتج</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="الاسم"
          v-model="recordData.name"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          label="الباركود"
          v-model="recordData.barcode"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          type="number"
          :label="$t('cost')"
          v-model="recordData.cost"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          type="number"
          :label="$t('sale price')"
          v-model="recordData.sell_price"
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          :loading="submitLoading"
          @click="submit"
          >{{ $t('edit') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      submitLoading: false,
      product: {
        name: "",
        barcode: null,
        cost: null,
        sell_price: null,
      },
    };
  },
  props: {
    recordData: Object,
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        await axios.put(`/product/${this.recordData.id}`, this.recordData);
        this.$Notifications(
          this.$t('edit success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.dialog = false;
        this.$emit("submitted");
      } catch (err) {
      } finally {
        this.submitLoading = false;
      }
    },
  },
  // created() {
  //   console.log(this.recordData.id);
  //   for (let key in this.recordData) {
  //     this.product[key] = this.recordData[key];
  //   }
  // },
};
</script>

<style>
</style>