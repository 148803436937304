<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="2" v-if="returnAbility('product:store')">
          <app-add-product @submitted="fetchData(1)"></app-add-product>
        </v-col>
        <v-col md="3">
          <v-text-field hide-details outlined dense :label="$t('search by name')" v-model="name"></v-text-field>
        </v-col>
        <v-col md="3" class="d-flex justify-center align-center">
          <v-icon @click="fetchData(1)">mdi-magnify</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
      serverRoute="/product" :enableDelete="displayDeleteIcon">
      <template slot="editFeature" slot-scope="{ row }">
        <app-edit-product v-if="
          returnAbility('product:update') && returnAbility('product:show')
        " :recordData="row" @submitted="fetchData(1)"></app-edit-product>
      </template>
    </app-base-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addProduct from "./addProduct.vue";
import editProduct from "./editProduct.vue";
export default {
  components: {
    appEditProduct: editProduct,
    appAddProduct: addProduct,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "buffetProducts/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchProducts: "buffetProducts/fetchProducts",
    }),
    fetchData(page) {
      this.fetchProducts({
        page,
        name: this.name,
      });
    },
  },
  created() {
    if (this.returnAbility("product:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>