<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
        <v-icon size="20">mdi-plus</v-icon>
        {{ $t('add product') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2> {{ $t('add product') }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="product.name" outlined dense></v-text-field>
        <v-text-field :label="$t('parcode')" v-model="product.barcode" outlined dense></v-text-field>
        <v-text-field type="number" :label="$t('cost')" v-model="product.cost" outlined dense></v-text-field>
        <v-text-field type="number" :label="$t('sale price')" v-model="product.sell_price" outlined
          dense></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" :loading="submitLoading" @click="submit">{{ $t('add') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      submitLoading: false,
      product: {
        name: null,
        barcode: null,
        cost: null,
        sell_price: null,
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        await axios.post("/product", this.product);
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        for (let key in this.product) {
          this.product[key] = null;
        }
        this.dialog = false;
        this.$emit("submitted");
      } catch (err) {
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style>

</style>